<template>
  <div class="settings">
    <app-redirect/>
  </div>
</template>

<script>
import appRedirect from "@/pages/components/appRedirect"
export default {
  name: "src-pages-body-workstation-finance-v1_mobile",
  components: { appRedirect }
}

</script>
